import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/Seo'
import Layout from '../layouts/'
import PageBody from '../components/Pages/MarketSimple'

const IndexPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        queryCoinId="BTC"
        type="coin-view"
        keywords="Bitcoin, Bitcoin Chile, Comprar Bitcoin, Como comprar Bitcoin"
      />
      <PageBody location={location} staticCoin={'BTC'} />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object
}

export default IndexPage
